<template>
  <div class="dialogWrapper">
    <p class="titleText">Vaš nalog je uspešno kreiran.</p>
    <button class="logInButton" @click.prevent="redirectToLogin">Prijavi se</button>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "RegistrationFinished",
  methods: {
    ...mapActions(["RemoveNewUserInfo"]),
    async removeDataFromVuex() {
      await this.RemoveNewUserInfo().then(() => {
        this.$emit("basicInfoEntered");
      });
    },
    redirectToLogin() {
      let queryEmail = this.newUserData.email;
      this.removeDataFromVuex();
      this.$router.push(`/prijava?email=${queryEmail}`);
    },
  },
  computed: {
    newUserData() {
      return this.$store.getters.StateNewUser;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.titleText {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 1rem;
}

.logInButton {
  width: 70%;
  padding: 0.8rem;
  color: #fff;
  background-color: #137547;
  border-radius: 4px;
}
</style>
